import React from 'react';
import mining from '../../images/mining.png';

import './About.scss';

function About(): React.ReactElement {
  return (
    <div className="About">
      <img src={mining} alt="mining" className="Image" />
    </div>
  );
}

export default About;
