import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './MenuItem.scss';

type Props = {
  pathName: string;
  path: string;
  setIsOpen: (v: boolean) => void;
};

export default function MenuItem({ pathName, path, setIsOpen }: Props): React.ReactElement {
  const location = useLocation();
  return (
    <div className="MenuItem">
      <div className="Item">
        <Link
          className={`Nav-Link ${location.pathname === path ? 'Active' : ''}`}
          to={path}
          style={{ textDecoration: 'none', display: 'block' }}
          onClick={() => setIsOpen(false)}
        >
          {pathName}
        </Link>
      </div>
      <div className="Line" />
    </div>
  );
}
