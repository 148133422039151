import React, { useState } from 'react';
import './Rarity.scss';

const attributes = [
  { id: 1, value: 'Background' },
  { id: 2, value: 'Attr 2' },
  { id: 3, value: 'Attr 3' },
  { id: 4, value: 'Attr 4' },
  { id: 5, value: 'Attr 5' },
  { id: 6, value: 'Attr 6' },
  { id: 7, value: 'Attr 7' },
];

const cards = [
  {
    id: 1,
    attributeId: 1,
    textValue: 'Attr 1',
    percentage: 50.2,
    isRare: true,
    imgPath: '/assets/lady.png',
  },
  {
    id: 2,
    attributeId: 1,
    textValue: 'Attr 2',
    percentage: 50.3,
    isRare: false,
    imgPath: '/assets/0.png',
  },
  {
    id: 3,
    attributeId: 1,
    textValue: 'Attr 3',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/1.png',
  },
  {
    id: 4,
    attributeId: 1,
    textValue: 'Attr 4',
    percentage: 50.3,
    isRare: false,
    imgPath: '/assets/2.png',
  },
  {
    id: 5,
    attributeId: 1,
    textValue: 'Attr 5',
    percentage: 50.3,
    isRare: false,
    imgPath: '/assets/3.png',
  },
  {
    id: 6,
    attributeId: 1,
    textValue: 'Attr 6',
    percentage: 50.3,
    isRare: false,
    imgPath: '/assets/4.png',
  },
  {
    id: 7,
    attributeId: 2,
    textValue: 'Attr 1',
    percentage: 62.2,
    isRare: false,
    imgPath: '/assets/5.png',
  },
  {
    id: 8,
    attributeId: 2,
    textValue: 'Attr 2',
    percentage: 60.0,
    isRare: true,
    imgPath: '/assets/5.png',
  },
  {
    id: 9,
    attributeId: 2,
    textValue: 'Attr 3',
    percentage: 55.0,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 10,
    attributeId: 2,
    textValue: 'Attr 4',
    percentage: 20.0,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 11,
    attributeId: 2,
    textValue: 'Attr 5',
    percentage: 10.0,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 12,
    attributeId: 3,
    textValue: 'Attr 1',
    percentage: 50.2,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 13,
    attributeId: 3,
    textValue: 'Attr 2',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 14,
    attributeId: 3,
    textValue: 'Attr 3',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 15,
    attributeId: 3,
    textValue: 'Attr 4',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 16,
    attributeId: 3,
    textValue: 'Attr 5',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 17,
    attributeId: 3,
    textValue: 'Attr 6',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
  {
    id: 18,
    attributeId: 3,
    textValue: 'Attr 6',
    percentage: 50.3,
    isRare: true,
    imgPath: '/assets/0.png',
  },
];

function Rarity(): React.ReactElement {
  const [selectedAttribute, setSelectedAttribute] = useState(1);

  const handleClickAttribute = (attribId: number) => {
    setSelectedAttribute(attribId);
  };

  return (
    <div className="Rarity">
      <div className="Attributes">
        {attributes.map((attribute, i) => (
          <div
            key={attribute.id}
            className={`Attribute ${attribute.id === selectedAttribute ? 'Active' : ''}`}
            onClick={() => handleClickAttribute(attribute.id)}
          >
            {attribute.value}
          </div>
        ))}
      </div>
      <div className="Cards">
        {cards
          .filter((card) => card.attributeId === selectedAttribute)
          .map((card) => (
            <div key={card.id} className="Card">
              {card.isRare ? (
                <div className="Rare">
                  <span className="RareStar">*</span> RARE
                </div>
              ) : (
                <div className="Rare"></div>
              )}
              <img src={card.imgPath} alt="blankImage" />
              {card.textValue} <br />
              {card.percentage + '%'}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Rarity;
