import React from 'react';
import './MenuButton.scss';

type Props = {
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
};

function MenuButton({ isOpen, setIsOpen }: Props): React.ReactElement {
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="MenuButton" onClick={handleClick}>
      <div
        className="Line"
        style={{
          transform: isOpen ? 'rotate(45deg)' : 'none',
          transformOrigin: 'top left',
          marginBottom: '5px',
        }}
      />
      <div
        className="Line"
        style={{
          opacity: isOpen ? 0 : 1,
          transform: isOpen ? 'translateX(-16px)' : 'none',
        }}
      />
      <div
        className="Line"
        style={{
          transform: isOpen ? 'translateX(-1px) rotate(-45deg)' : 'none',
          transformOrigin: 'top left',
          marginTop: '5px',
        }}
      />
    </div>
  );
}

export default MenuButton;
