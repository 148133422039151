import React, { useState } from 'react';

import './RoadMap.scss';

// 0 - not completed
// 1 - completed
// 2 - deleted
const roadMapItems = [
  {
    id: 1,
    title: 'Stage 1',
    subTitle: 'CREATE 9,999 UNIQUE NFTs',
    desc: 'COLLECTION_NAME NFTs are created!',
    status: 1,
  },
  {
    id: 2,
    title: 'Stage 2',
    subTitle: 'MARKET PLACE INTEGRATION - MARKET',
    desc: 'This is currently in progress',
    status: 0,
  },
  {
    id: 3,
    title: 'Stage 3',
    subTitle: 'SPECIAL UPCOMING LAUNCH OF PLATFORM',
    desc: `One of the main reasons the platform is important is because 
    it will be used to One of the main reasons the platform is important is
    because it will be used to One of the main reasons the platform is important 
    is because it will be used to One of the main reasons the platform is important is because it will be used to `,
    status: 0,
  },
  {
    id: 4,
    title: 'Stage 4',
    subTitle: 'AIRDROP WITH OTHER ADDONS',
    desc: `One of the main reasons the platform is important is because it will 
    be used to One of the main reasons the platform is important is because 
    it will be used to One of the main reasons the platform is important is because`,
    status: 1,
  },
  {
    id: 5,
    title: 'Stage 5',
    subTitle: 'FINAL RELASE',
    desc: `THIOne of the main reasons the platform is important is because it will be 
    used to One of the main reasons the platform is important is because it will be used 
    to One wda9wdia0w9dia0w9ida0w9dia0w a0w9diaw9 90awdi a09wd aw9i daw0d0awdi  `,
    status: 0,
  },
];

function RoadMap(): React.ReactElement {
  return (
    <div className="RoadMap">
      <div className="Content">
        {roadMapItems.map((item) => (
          <div className="RoadMapItem" key={item.title}>
            <div>
              <div className="Title">{item.title}</div>
              <div className="SubTitle">{item.subTitle}</div>
              <div className="Desc">{item.desc}</div>
            </div>
            <div style={{ cursor: 'pointer' }}>{item.status === 1 ? <div>[X]</div> : <div>[]</div>}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RoadMap;
