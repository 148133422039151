import React, { useState } from 'react';
import { Collapse as ReactCollapse } from 'react-collapse';
import './Collapse.scss';

type Props = {
  collapseTitle: string;
  collapseContent: string;
};

function Collapse({ collapseTitle, collapseContent }: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="Collapse" onClick={() => setIsOpen(!isOpen)}>
        {collapseTitle} {isOpen ? <div className="Toggle">↑</div> : <div className="Toggle">↓</div>}
      </div>
      <ReactCollapse isOpened={isOpen}>
        <div className="Collapse CollapseContent">{collapseContent}</div>
      </ReactCollapse>
    </>
  );
}

export default Collapse;
