import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Logo.scss';

function Logo(): React.ReactElement {
  const navigate = useNavigate();

  const navigateHome = () => {
    navigate('/');
  };

  return (
    <div className="Logo" onClick={navigateHome}>
      <div className="Logo-Img">
        <img src="/assets/dog.png" alt="Dog" height="30" width="35" />
      </div>
      COLLECTION_NAME
    </div>
  );
}

export default Logo;
