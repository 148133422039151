import React from 'react';
import Logo from '../logo/Logo';
import twitter from '../../images/twitter.png';
import logo2 from '../../images/logo2.png';
import solana from '../../images/solana.png';
import './Footer.scss';

function Footer(): React.ReactElement {
  return (
    <div className="Footer">
      <div className="Footer-Section1">
        <div className="Footer-Content">
          <div className="Footer-Section1-Title">COLLECTION_NAME</div>
          <div className="Description">Unique mining opportunities available to those who ask.</div>
          <div className="Contact">
            <a href="mailto: contact@site.com" style={{ textDecoration: 'none', color: '#9945FF' }}>
              contact@site.com
            </a>
          </div>
        </div>
        <div className="Footer-Content">
          <div className="Footer-Section1-Title">Resources</div>
          <div>Whitepaper</div>
          <div>FAQ</div>
          <div>Linktree</div>
        </div>
        <div className="Footer-Content">
          <div className="Footer-Section1-Title">Resources</div>
          <a
            href="https://twitter.com"
            style={{ textDecoration: 'none', color: '#9945FF' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitter} alt="twitter logo" width="50" height="50" />
          </a>
          <a
            href="https://discord.com"
            style={{ textDecoration: 'none', color: '#9945FF' }}
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo2} alt="logo2" width="50" height="50" />
          </a>
        </div>
      </div>
      <div className="Footer-Section2">
        <Logo />
        <div className="Footnote">
          Built on the <img src={solana} alt="solona" width="25" height="25" /> Solana blockchain.
        </div>
      </div>
    </div>
  );
}

export default Footer;
