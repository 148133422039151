import React from 'react';
import './MobileMenu.scss';

type Props = {
  isOpen: boolean;
  children?: React.ReactNode;
};

export default function MobileMenu({ isOpen, children }: Props): React.ReactElement {
  return (
    <div className="MobileMenu" style={{ height: isOpen ? '40%' : 0 }}>
      {isOpen ? <div className="MenuList">{children}</div> : null}
    </div>
  );
}
