import React from 'react';
import Collapse from '../../components/collapse/Collapse';
import Footer from '../../components/footer/Footer';
import './FAQ.scss';

const data = [
  {
    q: 'WHAT ARE COLLECTION_NAME?',
    a: 'These colleciton items are used for mining things',
  },
  {
    q: 'WHEN WILL MINING BE AVAILABLE?',
    a: 'These colleciton items are used for mining things',
  },
  {
    q: 'LOREM IPSUM',
    a: 'These colleciton items are used for mining things',
  },
  {
    q: 'LOREM IPSUM DOLOR SIT AMET?',
    a: 'These colleciton items are used for mining things',
  },
  {
    q: 'SUSPENDISSE NON PURUS CONSECTETUR?',
    a: 'These colleciton items are used for mining things',
  },
];

function FAQ(): React.ReactElement {
  return (
    <div className="FAQ">
      <div className="Main">
        <div className="Title">FAQ</div>
        <div className="Questions">
          {data.map((item) => (
            <Collapse key={item.q} collapseTitle={item.q} collapseContent={item.a} />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default FAQ;
